.m-section-headline {
    @include typo--h2(false);

    display: block;

    margin-bottom: spacer(4);

    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(3);
    }
}

.m-section-headline--centered {
    text-align: center;
}
