.m-expert-teaser__grid {
    display: flex;
    flex-wrap: wrap;

    margin-left: spacer(-4);
    margin-right: spacer(-4);

    @media all and (max-width: $tablet--landscape) {
        margin-left: spacer(-2);
        margin-right: spacer(-2);
    }
}

.m-expert-teaser__elem {
    width: 50%;

    padding: 0 spacer(4);

    margin-bottom: spacer(4);

    @media all and (max-width: $tablet--landscape) {
        padding: 0 spacer(2);
    }

    @media all and (max-width: $tablet--portrait) {
        width: 100%;

        margin-bottom: spacer(2);
    }
}

.m-expert-teaser {
    display: block;
}

.m-info-box {
    // margin-bottom: spacer(12);
}

.m-expert-teaser--on-white {
    padding: spacer(1);

    background-color: #fff;

    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.32);

    margin-bottom: spacer(-8);
}

.m-expert-teaser__image {
    @include ratio-image(4/3);

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    margin-bottom: spacer(1);
}

.m-expert-teaser__name {
    @include typo--h3;

    text-align: left;

    margin-bottom: spacer(0.5);
}

.m-expert-teaser__profession {
    @include typo--lato--bold;

    text-transform: uppercase;

    margin-bottom: spacer(0.5);
}

.m-expert-teaser__text {
    margin-bottom: spacer(0.5);
}

.m-expert-teaser__related-events a {
    @include typo--lato--bold;

    color: $base--color--main--1;
}

.m-expert-teaser__link {
    @include typo--lato--bold;

    color: $base--color--main--1;

    font-size: rem(16);

    text-transform: uppercase;

    display: inline-block;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
