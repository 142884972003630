.m-image-text__group {
    display: flex;
    flex-wrap: wrap;

    margin-left: spacer(-2);
    margin-right: spacer(-2);
    margin-bottom: spacer(-2);

    justify-content: center;
}

.m-image-text__elem {
    width: 33.33%;

    padding: 0 spacer(2);

    margin-bottom: spacer(2);

    @media all and (max-width: $tablet--landscape) {
        width: 50%;
    }

    @media all and (max-width: $phablet) {
        width: 100%;
    }
}

.m-image-text {
    text-align: center;
}

.m-image-text--has-link {
    display: block;

    &:hover {
        @include more-link-hover;
    }
}

.m-image-text__image {
    @include ratio-image(4/3);

    &:after {
        display: block;
        content: '';

        padding-top: 0;
    }

    margin-bottom: spacer(1);

    background-size: cover;
    background-position: center center;
}

.m-image-text__title {
    @include typo--h3;

    margin-bottom: spacer(1);
}

.m-image-text__link {
    margin-top: spacer(1);
}
