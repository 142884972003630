.m-filter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: spacer(-2);
}

.m-filter__element {
    @include typo--lato--bold;

    padding: 0 spacer(2);

    text-align: center;
    text-transform: uppercase;

    margin-bottom: spacer(2);

    transition: transform 0.2s ease-in-out;

    justify-content: center;

    &:hover {
        transition: transform 0.2s ease-in-out;
        transform: scale(1.2);
    }

    &:before {
        display: block;
        content: '';

        width: rem(70);
        height: rem(70);

        background-color: $base--color--main--3;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        margin: 0 auto;

        transition: border 0.3s ease-in-out;

        border-radius: 100%;

        margin-bottom: spacer(1);

        border: 5px solid #fff;
    }

    @media all and (max-width: $phablet) {
        width: 50%;

        padding: 0 spacer(0.5);

        text-transform: none;

        &:before {
            width: rem(50);
            height: rem(50);
        }
    }

    &.is-active {
        &:before {
            transition: border 0.3s ease-in-out;
            border: 5px solid $base--color--main--1;
        }
    }
}

.m-filter__element--kunst {
    &:before {
        background-image: url('../assets/images/icon--filter--kunst.svg');
    }
}

.m-filter__element--geschichte {
    &:before {
        background-image: url('../assets/images/icon--filter--geschichte.svg');
    }
}

.m-filter__element--familie {
    &:before {
        background-image: url('../assets/images/icon--filter--familie.svg');
    }
}

.m-filter__element--aktion {
    &:before {
        background-image: url('../assets/images/icon--filter--aktion.svg');
    }
}

.m-filter__element--natur {
    &:before {
        background-image: url('../assets/images/icon--filter--natur.svg');
    }
}
