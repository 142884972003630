.m-teaser__group {
    display: flex;
    flex-wrap: wrap;

    margin-left: rem(-$base--grid-gutter / 2);
    margin-right: rem(-$base--grid-gutter / 2);
}

.m-teaser__group-three {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-left: rem(-$base--grid-gutter / 2);
    margin-right: rem(-$base--grid-gutter / 2);

    @media all and (max-width: $tablet--landscape) {
        margin-left: 0;
        margin-right: 0;
    }

    .m-teaser__group-three-element {
        width: 33.33%;
        padding: 0 rem($base--grid-gutter/2);

        display: flex;

        @media all and (max-width: $tablet--landscape) {
            width: 50%;
        }

        @media all and (max-width: $tablet--landscape) {
            width: 100%;
            padding: 0;

            margin: 0 rem($base--grid-gutter/2);

            .js-teaser-slider & {
                margin-bottom: 0;
            }
        }
    }

    .flickity-button {
        top: 100%;
    }

    &.previous {
        left: 0;
    }

    &.next {
        right: 0;
    }
}

.m-teaser {
    width: 100%;

    display: flex;
    flex-direction: column;

    .m-bg-container & {
        background-color: #fff;
    }
}

.m-teaser__image {
    @include ratio-image(16/9);

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    margin-bottom: spacer(2);
}

.m-teaser__content {
    text-align: center;
}

.m-teaser__title {
    @include typo--h3;
}

.m-teaser__text {
    @include typo--copy;

    margin-bottom: spacer(3);

    flex: auto;
}

.m-teaser__link {
    margin: auto;
}

.m-teaser__group-button {
    @media all and (max-width: $tablet--landscape) {
        margin-top: rem(48 + 20);
    }
}
