/**

Generell:

Jedes Content Element umfasst immer einen Container und bekommt einen modifier für die Breite, sowie für den Abstand nach unten

	<div class="m-container m-container--small m-container-mb" data-ce="name">
		<div class="m-module-name">
		 ...
		</div>
	</div>

Ein Modul wird dann in einen Container eingebunden. Die Breite des Module bestimmt also der Container.

Weiterhin bekommt ein Container immer einen fest definierten Abstand nach unten. Dieser kann sein:

.m-container--mb - Default Abstand
.m-container--mb--small - Kleiner Abstand

/*

Parameter: $size
--------------------

Those sizes are defined by the design. If you need more or less talk to the design

*/

@mixin container($size) {
    margin-left: auto;
    margin-right: auto;

    box-sizing: content-box;

    padding-left: spacer(4);
    padding-right: spacer(4);

    overflow: hidden;

    @media all and (max-width: $tablet--portrait) {
    	padding-left: spacer(3);
    	padding-right: spacer(3);
    }

    @media all and (max-width: $phablet) {
    	padding-left: spacer(1);
    	padding-right: spacer(1);
    }

	@if ($size == 'small') {
		@include max-width(8);
	}

    @if ($size == 'medium') {
        @include max-width(10);
    }

    @if ($size == 'large') {
        @include max-width(12);
    }
}

// Default Abstand von einem CE nach unten
@mixin container-mb {
	margin-bottom: spacer(6);

	@media all and (max-width: $tablet--landscape) {
		margin-bottom: spacer(3);
	}
}

@mixin container--centered {
    text-align: center;
}

// Setzt die Eigenschaften eines Containers zurück, wenn dieser in einem Akkordion verwendet wird.
@mixin reset-container() {
	padding-left: 0;
	padding-right: 0;
	max-width: auto;
	margin-bottom: 0;
}

/**

Klassen

**/

.m-container {
	margin-left: auto;
	margin-right: auto;
}

/* Spezial Klasse für CE Text, welches man optional nach links ausrichten kann */
.m-container--left {
	margin-left: 0 !important;

	.m-container__headline {
		text-align: left;
	}
}

/* Standart Headline eines CE's */
.m-container__headline {
	@include typo--h2;

	text-align: center;
}

.m-container--large {
	@include container(large);
}

.m-container--medium {
	@include container(medium);
}

.m-container--small {
	@include container(small);
}

.m-container--mb {
	@include container-mb;
}

.m-container--centered {
    @include container--centered;
}

.m-container--overflow {
    overflow: visible;
}
