.m-related-events {
    display: none; // will be visible via .flickity-enabled

    .flickity-button {
        margin-top: rem(-100);
    }
}

.m-related-events__element {
    width: 50%;

    padding: 0 spacer(1);

    @media all and (max-width: $tablet--portrait) {
        width: 100%;
    }
}
