.m-button__center-container {
    text-align: center;
}

@mixin m-button {
	@include typo--lato--bold;

	display: inline-block;

	text-transform: uppercase;

	letter-spacing: 0.5px;

    border-radius: 27px;
    line-height: 1.5;

    padding: rem(16) rem(32);

    font-size: rem(16);

    &:hover {
        text-decoration: none;
    }

	// If the button has no more space the content's get centered;
	// Use white-space: nowrap; is preferred but with the risk of overflows
	text-align: center;

	transition: all 0.1s ease-out;

	&.is-external {
		&:after {
			display: inline-block;

			margin-left: spacer(1);

			content: '';
			width: rem(12);
			height: rem(12);

			background-size: contain;
			background-image: url('../assets/images/icon--external-link--white.svg');
		}
	}
}

@mixin m-button--primary {
    color: $base--color--main--1;

    background-color: $base--color--main--1;

    color: #fff;

    border: 1px solid $base--color--main--1;

    &:hover {
        background-color: darken($base--color--main--1, 5%);
        border: 1px solid darken($base--color--main--1, 5%);
    }
}

@mixin m-button--secondary {
    background-color: transparent;
    color: $base--color--main--1;

    border: 1px solid $base--color--main--1;

    &:hover {
        background-color: $base--color--main--1;
        color: #fff;
    }

    &.is-external {
        &:after {
            background-image: url('../assets/images/icon--external-link--dark.svg');
        }
    }
}

@mixin m-button--has-black-arrow {
    position: relative;

    padding-right: rem(50);

    &:after {
        content: "";
        display: inline-block;

        position: absolute;

        right: rem(25);
        top: 50%;
        transform: translateY(-50%);

        transition: transform 0.2s ease-in-out;

        background-image: url('../assets/images/icon--double-arrow-right--black.svg');
        background-size: contain;
        background-repeat: no-repeat;

        width: rem(12);
        height: rem(12);
    }

    &:hover {
        &:after {
            transition: transform 0.2s ease-in-out;
            transform: translate(2px, -50%);
        }
    }
}

@mixin m-button--has-white-arrow {
    position: relative;

    padding-right: rem(50);

    &:after {
        content: "";
        display: inline-block;

        position: absolute;

        right: rem(25);
        top: 50%;
        transform: translateY(-50%);

        transition: transform 0.2s ease-in-out;

        background-image: url('../assets/images/icon--double-arrow-right--white.svg');
        background-size: contain;
        background-repeat: no-repeat;

        width: rem(12);
        height: rem(12);
    }

    &:hover {
        &:after {
            transition: transform 0.2s ease-in-out;
            transform: translate(2px, -50%);

            background-image: url('../assets/images/icon--double-arrow-right--black.svg');
        }
    }
}

.m-button {
    @include m-button;
}

.m-button--primary {
    @include m-button--primary;
}

.m-button--secondary {
    @include m-button--secondary;
}

.m-button--has-black-arrow {
    @include m-button--has-black-arrow;
}

.m-button--has-white-arrow {
    @include m-button--has-white-arrow;
}

.m-button--has-two-lines {
    border-radius: 72px;
}
