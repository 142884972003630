.m-intro {
	text-align: center;

    @media all and (max-width: $phablet) {
        text-align: left;
    }
}

.m-intro__headline {
	@include typo--h2(false);

    margin-bottom: spacer(2);
}

.m-intro__text {
	p {
		@include typo--intro;

        a {
            @include link-style();
        }
	}
}
