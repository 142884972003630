.m-fact-box {
    background-color: $base--color--main--3;
    padding: spacer(2) spacer(2) 0 spacer(2);

    @media all and (max-width: $phablet) {
        padding: spacer(1);
    }
}

.m-fact-box__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-left: spacer(-1);
    margin-right: spacer(-1);
}

.m-fact-box__element {
    width: 33.33%;

    padding: 0 spacer(1);

    margin-bottom: spacer(2);

    @media all and (max-width: $tablet--landscape) {
        width: 50%;
    }

    @media all and (max-width: $phablet) {
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.m-fact-box__icon {
    width: rem(30);
    height: rem(30);

    margin-bottom: spacer(0.5);


    background-size: contain;
    background-repeat: no-repeat;
}

.m-fact-box__icon--termine {
    background-image: url('../assets/images/icon--calendar.svg');
}

.m-fact-box__icon--treffpunkt {
    background-image: url('../assets/images/icon--location.svg');
}

.m-fact-box__icon--beitrag {
    background-image: url('../assets/images/icon--currency.svg');
}

.m-fact-box__title {
    @include typo--lato--bold;

    font-size: rem(16);

    text-transform: uppercase;
}
