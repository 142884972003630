.m-bg-container {
    padding-top: spacer(4);
    padding-bottom: spacer(4);

    background-color: $base--color--main--3;

    @media all and (max-width: $phablet) {
        padding-top: spacer(2);
    }
}

.m-bg-container__headline {
    @include typo--h2;

    text-align: center;

    margin-bottom: spacer(4);

    @media all and (max-width: $phablet) {
        margin-bottom: spacer(2);
    }
}
