.m-footer {
    background-color: $base--color--main--2;
    color: $base--color--main--2;

    padding-top: spacer(2);
    padding-bottom: spacer(2);

    font-size: rem(16);
    line-height: rem(18);

    color: #fff;
}

.m-footer__container {
    position: relative;

    @include container(large);
}

// Back To Top

.m-footer__back-to-top {
    @include container(false);

    text-align: right;

    margin-bottom: spacer(4);

    display: none;

    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(2);
    }
}

.m-footer__back-to-top__icon {
    width: rem(48);
    height: rem(48);

    cursor: pointer;

    background-size: contain;
    background-image: url('../assets/images/icon--backtotop.svg');

    margin-left: auto;
}

.m-footer__group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: spacer(2);

    @media all and (max-width: $tablet--landscape) {
        display: block;
    }
}

.m-footer__logos {
    display: flex;
}

.m-footer__logo {
    width: rem(180);

    background-image: url('../assets/images/logo--white.svg');
    background-size: contain;
    background-repeat: no-repeat;

    margin-right: spacer(4);

    &:after {
        display: block;
        content: '';

        padding-top: calc(100% / (151/92));
    }

    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(2);

        margin-right: spacer(2);
    }
}

.m-footer__logo_2 {
    display: block;

    width: rem(180);

    background-image: url('../assets/images/logo--aka.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &:after {
        display: block;
        content: '';

        padding-top: calc(100% / (151/92));
    }

    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(2);
    }
}

.m-footer__logo_3 {
    display: block;

    width: rem(250);

    background-image: url('../assets/images/logo-sparkasse-koeln-bonn@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-left: spacer(4);

    &:after {
        display: block;
        content: '';

        padding-top: calc(100% / (250/110));
    }

    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(2);

        margin-left: spacer(2);
    }
}

.m-footer__social {
    display: flex;

    margin-bottom: spacer(2);
}

.m-footer__social-icon {
    position: relative;

    display: inline-block;

    width: rem(45);
    height: rem(45);

    margin: 0 spacer(0.5);

    border-radius: 100%;

    text-indent: -9999px;

    background-size: cover;
}

.m-footer__social-icon--facebook {
    background-image: url('../assets/images/icon--facebook.svg');
}

.m-footer__social-icon--twitter {
    background-image: url('../assets/images/icon--twitter.svg');
}

.m-footer__social-icon--instagram {
    background-image: url('../assets/images/icon--instagram.svg');
}

.m-footer__social-icon--tripadvisor {
    background-image: url('../assets/images/icon--tripadvisor.svg');
}

.m-footer__newsletter-button {
    @extend .m-button;
    @extend .m-button--secondary;
    @extend .m-button--has-white-arrow;

    border-color: #fff;
    color: #fff;

    &:hover {
        background-color: #fff;
        color: $base--color--text;
    }
}

.m-footer__nav {
    @include typo--lato--bold;

    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: $phablet) {
        display: block;
    }

    text-transform: uppercase;

    margin-bottom: spacer(1);

    li {
        margin-right: spacer(2);
        margin-bottom: spacer(1);

        @media all and (max-width: $phablet) {
            display: block;
        }

        a {
            &:hover,
            &.is-active {
                text-decoration: underline;
            }
        }
    }
}

.m-footer__copyright {
    @include typo--lato--bold;
}
