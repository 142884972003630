.m-cookie-warning {
	position: fixed;

	right: 0;
	bottom: 0;

	background-color: $base--color--main--2;

	color: $base--color--main--2;

	display: flex;
    display: none !important;

	transform: translateY(100%);
	transition: transform 0.4s ease-in-out;

	&.is-in {
		transform: translateY(0);
		transition: transform 0.4s ease-in-out;
	}

    height: rem($base--booking--height);

	@media all and (max-width: $phablet) {
		left: 0;

		display: block;

		text-align: center;

        height: auto;
	}
}

.m-cookie-warning__text {
    @include typo--copy;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 spacer(3);

	@media all and (max-width: $phablet) {
        padding: spacer(3);
	}

    a {
        @include link-style;

        border: none;

        &:hover {
            text-decoration: none !important;
            border-bottom: 1px solid;
        }

        @media all and (max-width: $phablet) {
            &:before {
                display: block;
                content: '\A';
            }
        }
    }
}

.m-cookie-warning__button {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    margin-left: auto;

    padding: 0 spacer(3);
    background-color: $base--color--main--3;
    transition: background-color $base--global-transition-speed ease-in-out;

    &:hover {
        background-color: $base--color--main--2;
        transition: background-color $base--global-transition-speed ease-in-out;
    }

    @media all and (max-width: $phablet) {
        padding: spacer(2);
    }
}
