.m-cat-teaser__group {
    display: flex;
    flex-wrap: wrap;

    margin-left: spacer(-1);
    margin-right: spacer(-1);
}

.m-cat-teaser__elem {
    width: 33.33%;

    padding: 0 spacer(1);
    margin-bottom: spacer(1);

    @media all and (max-width: $tablet--landscape) {
        width: 50%;
    }

    @media all and (max-width: $tablet--portrait) {
        width: 100%;
    }
}

.m-cat-teaser {
    display: block;

    background-color: $base--color--main--3;

    @media all and (min-width: $post-tablet--landscape) {
        &:hover {
            .m-cat-teaser__text {
                transition: opacity 0.2s ease-in-out;
                opacity: 1;
            }
        }
    }
}

.m-cat-teaser__image {
    @include ratio-image(4/3);
}

.m-cat-teaser__text {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: rgba($base--color--main--3, 0.95);

    padding: spacer(1);
}

.m-cat-teaser__title {
    @include typo--lato--bold;

    font-size: rem(22);

    padding: spacer(1);
    text-align: center;
}
