/* Styles only applied to the `index.html` for testing purpose */

.m-module-header {
	@include typo--copy;

	background-color: $base--color--gray--1;
	padding: spacer(1);

	color: $base--color--main--1;

	margin-bottom: spacer(2);

	display: none;
}

@mixin color-square {
	width: 500px;
	height: 100px;

	margin-bottom: spacer(2);
}

.base--color--main--1 {
	@include color-square;
	background-color:  #000;

	&:after {
		display: inline-block;
		background-color: #fff;
		padding: 10px;
		color: #000;
		content: 'base--color--main--1';
	}
}

.base--color--main--2 {
	@include color-square;
	background-color:  #fff;

	&:after {
		display: inline-block;
		background-color: #fff;
		padding: 10px;
		color: #000;
		content: 'base--color--main--2';
	}
}

.base--color--main--3 {
	@include color-square;
	background-color:  #151515;

	&:after {
		display: inline-block;
		background-color: #fff;
		padding: 10px;
		color: #000;
		content: 'base--color--main--3';
	}
}

.base--color--gray--1 {
	@include color-square;
	background-color:  #d6d6d6;

	&:after {
		display: inline-block;
		background-color: #fff;
		padding: 10px;
		color: #000;
		content: 'base--color--gray--1';
	}
}
