/**

Example:

<div class="mod_module__ratio-image">
    <img src="..">
<div>

.mod_module__ratio-image {
    @include ratio-image(7/5)
}

Parameter: $hoverEffect
--------------------------

Currently in work ... Applied currently only to CE Teaser Mittel to have the arrow animated

**/

@mixin ratio-image($ratio: false, $hoverEffect: false) {
    // @include spinner(before);

    position: relative;

    overflow: hidden;

    display: block;

    background-color: $base--color--gray--1;

    @if ($ratio != false) {
        &:after {
            display: block;
            content: '';
            padding-top: (100% / ( $ratio ));
        }
    }

    iframe,
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        opacity: 1;
        transition: opacity $base--img-fade-in-speed;

        &.lazyloaded {
            opacity: 1;
        }
    }

    &.loaded {
        img {
            opacity: 1;
        }
    }

    @if ($hoverEffect != false) {
        @include image-hover-effect()
    }
}

.m-ratio-image {
	@include ratio-image();
}
