 .m-event-teaser-wrapper {
    position: relative;

    margin-bottom: spacer(4);

    &:before {
        display: block;
        content: '';

        position: absolute;

        top: 0;
        bottom: rem(-50);
        left: 50%;

        width: 1px;

        background-color: #c3c3c3;

        z-index: -1;
    }

    // Triangle

    // &:after {
    //     content: '';
    //     position: absolute;

    //     top: 100%;
    //     left: 50%;

    //     transform: translateX(-50%);

    //     margin-left: rem(1);

    //     width: 0;
    //     height: 0;
    //     border-left: 11px solid transparent;
    //     border-right: 11px solid transparent;
    //     border-top: 11px solid #c3c3c3;

    //     clear: both;
    // }

    // Dot

    &:after {
        content: '';
        position: absolute;

        left: 50%;
        bottom: rem(-50);

        transform: translateX(-50%);

        margin-left: rem(1);

        width: rem(10);
        height: rem(10);

        border-radius: 100%;
        background-color: $base--color--main--1;

        clear: both;
    }

}

.m-event-teaser-group {
    @media all and (min-width: $post-tablet--landscape) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        margin-left: spacer(-4);
        margin-right: spacer(-4);

        text-align: center;
    }
}

.m-event-teaser-group--has-one {
    justify-content: center;
}

.m-event-teaser-group__headline {
    text-align: center;

    margin-top: spacer(4);
    margin-bottom: spacer(4);

    &:first-child {
        margin-top: 0;
    }

    span {
        @include typo--lato--bold;

        display: inline-block;

        padding: spacer(1);

        background-color: $base--color--main--1;

        color: #fff;
    }

    @media all and (max-width: $phablet) {
        margin-top: spacer(2);
        margin-bottom: spacer(2);
    }
}

.m-event-teaser-group__element {
    @media all and (min-width: $post-tablet--landscape) {
       width: 50%;

       padding: 0 spacer(4);

       &:nth-child(even) {
           margin-top: spacer(12);
       }
    }

    @media all and (max-width: $tablet--landscape) {
        margin: 0 auto;

        max-width: 100%;

        margin-bottom: spacer(4);
    }

    @media all and (max-width: $phablet) {
        margin-bottom: spacer(2);
    }

    transition: opacity $base--global-transition-speed ease-in-out;
    opacity: 1;

    &.is-filtered {
        .m-event-teaser__image__inner,
        .m-event-teaser__box__inner {
            transition: opacity $base--global-transition-speed ease-in-out;
            opacity: 0.5;
        }
    }
}

.m-event-teaser {
    display: block;

    max-width: rem(500);
    margin: 0 auto;

    @media all and (min-width: $phablet) {
        &:hover {
            .m-event-teaser__title {
                color: $base--color--main--1;
            }

            .m-event-teaser__image__inner,
            .m-event-teaser__box__inner {
                transition: opacity $base--global-transition-speed ease-in-out;
                opacity: 1;
            }

            @media all and (min-width: $post-tablet--landscape) {
                .m-event-teaser__box {
                    transition: transform 0.2s ease-in-out;
                    transform: translateY(-50%) scale(1.05);
                }
            }
        }
    }
}

.m-event-teaser__image {
    background-color: #fff;
}

.m-event-teaser__image__inner {
    @include ratio-image(4/3);

    background-position: center center;
    background-size: cover;

    transition: opacity $base--global-transition-speed ease-in-out;
    opacity: 1;
}

.m-event-teaser__box {
    padding: spacer(1) spacer(2) spacer(1) spacer(1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.32);

    width: 80%;

    margin: auto;

    transform: translateY(-50%) scale(1);

    background-color: #fff;

    transition: transform 0.2s ease-in-out;

    &:after {
        content: "";
        display: inline-block;

        position: absolute;

        right: rem(25);
        top: 50%;
        transform: translateY(-50%);

        margin-top: rem(2);

        background-image: url('../assets/images/icon--double-arrow-right--black.svg');
        background-size: contain;
        background-repeat: no-repeat;

        width: rem(16);
        height: rem(16);

        @media all and (max-width: $tablet--landscape) {
            display: none;
        }
    }

    @media all and (max-width: $tablet--landscape) {
        width: 100%;
        transform: none;
    }
}

.m-event-teaser__box__inner {
    transition: opacity $base--global-transition-speed ease-in-out;
    opacity: 1;
}

.m-event-teaser__tag {
    text-align: left;
    margin-bottom: spacer(0.5);
    text-transform: uppercase;
}

.m-event-teaser__title {
    @include typo--h3(false);

    text-align: left;

    padding-right: spacer(1);
}
