/**
 * z-index
 *
 * This file contains all z-indexes of the whole project
 *
 */

.m-header {
	z-index: 10;
}

.m-header__nav-trigger {
	z-index: 200;
}

.m-select {
	z-index: 250;
}

.m-cookie-warning {
    z-index: 275;
}

.m-nav {
	z-index: 300;
}

.m-nav__close-btn {
	z-index: 400;
}
