.m-menue {
    @include typo--lato--bold;

    border-top: 1px solid #c3c3c3;
}

.m-menue__price-row {
    display: flex;

    justify-content: space-between;

    padding: spacer(1.5) 0;

    border-bottom: 1px solid #c3c3c3;

    &:last-child {
        margin-bottom: 0;
    }

    @media all and (max-width: $tablet--portrait) {
        display: block;

        padding: spacer(1) 0;
    }
}

.m-menue__price-row--no-border {
    border: none;

    padding-bottom: spacer(0.5);

    + .m-menue__price-row {
        padding-top: 0;
    }

    @media all and (max-width: $tablet--portrait) {
        margin-bottom: spacer(1);
    }
}

.m-menue__key {
    @media all and (max-width: $tablet--portrait) {
        margin-bottom: spacer(0.25);
    }
}
