.m-date-teaser__group {
    display: flex;
    flex-wrap: wrap;

    margin-left: spacer(-1);
    margin-right: spacer(-1);

    margin-bottom: spacer(-2);
}

.m-date-teaser__elem {
    width: 50%;

    padding: 0 spacer(1);

    margin-bottom: spacer(2);

    @media all and (max-width: $tablet--portrait) {
        width: 100%;
    }
}

.m-date-teaser {
    display: block;

    &:hover {
        .m-date-teaser__box {
            backface-visibility: hidden;
            transition: transform 0.2s ease-in-out;
            transform: scale(1.05);
        }

        .m-date-teaser__title {
            backface-visibility: hidden;
            transition: color 0.2s ease-in-out;
            color: $base--color--main--1;
        }
    }
}

.m-date-teaser__image {
    @include ratio-image(4/3);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.m-date-teaser__box {
    position: relative;

    padding: spacer(2);

    background-color: #fff;

    width: 90%;

    margin: spacer(-2) auto 0;

    z-index: 1;

    text-align: center;

    transition: transform 0.2s ease-in-out;
    transform: scale(1);


    @media all and (max-width: $phablet) {
        padding: spacer(2) spacer(1);

        width: 100%;

        margin: 0 auto;
    }
}

.m-date-teaser__title {
    @include typo--h3;

    margin-bottom: spacer(2);

    transition: color 0.2s ease-in-out;
}

.m-date-teaser__text {
    margin-bottom: spacer(2);
}

.m-date-teaser__dates {
    @include typo--lato--regular;

    margin-bottom: spacer(2);
}

.m-date-teaser__more-icon {
    &:after {
        display: block;
        content: url('../assets/images/icon--double-arrow-right--gray.svg');
    }
}
