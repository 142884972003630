.m-week-switcher {
    display: flex;
    justify-content: center;

    margin-bottom: spacer(4);

    @media all and (max-width: $tablet--landscape) {
        display: block;

        .m-week-switcher__button {
            display: block;
            margin: 0 auto;
            margin-bottom: spacer(2);
        }
    }
}

.m-week-switcher__button {
    @extend .m-button;
    @extend .m-button--secondary;

    margin: 0 spacer(1);

    &.is-active {
        @extend .m-button--primary;
    }
}

.m-week-switcher__button--active {
    @extend .m-button;

    margin: 0 spacer(1);
}
