.m-info-box {
    background-color: $base--color--main--3;

    padding: spacer(3);

    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: $tablet--landscape) {
        padding: spacer(1);
    }
}

.m-info-box__left {
    width: 50%;

    padding-right: spacer(3);

    @media all and (max-width: $tablet--landscape) {
        width: 100%;

        padding-right: 0;
        margin-bottom: spacer(3);
    }
}

.m-info-box__right {
    width: 50%;

    @media all and (max-width: $tablet--landscape) {
        width: 100%;
    }
}

.m-info-box__headline {
    @include typo--h2;

    margin-bottom: spacer(1);
}

.m-info-box__table {
    margin-bottom: spacer(3);

    @media all and (max-width: $phablet) {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.m-info-box__row {
    display: flex;

    padding: spacer(0.5) 0;

    border-bottom: 1px solid #4eb78e;

    @media all and (max-width: $phablet) {
        display: block;
    }
}

.m-info-box__key {
    @include typo--lato--bold;

    flex: 0 0 rem(150);
}
