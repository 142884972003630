@mixin typo--lato--light {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@mixin typo--lato--regular {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin typo--lato--bold {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: normal;
}

html {
    @include typo--lato--light;

    font-size: $base--font-size;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    // -webkit-font-smoothing: antialiased;
    line-height: $base--line-height;
    color: $base--color--text;
}

// Headlines

@mixin typo--h1 ($margin-bottom: true) {
    @include typo--lato--light;

    font-size: 60px;
    line-height: 1.07;
    letter-spacing: -0.5px;

    @media all and (max-width: $phablet) {
        font-size: 36px;
        line-height: 1.11;
        letter-spacing: -0.3px;
    }

    @if ($margin-bottom) {
        margin-bottom: spacer(3);
    }
}

@mixin typo--h2 ($margin-bottom: true) {
    @include typo--lato--regular;

    font-size: 48px;
    line-height: 1.17;
    letter-spacing: -0.4px;

    @media all and (max-width: $phablet) {
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -0.4px;
    }
}

@mixin typo--h3 ($margin-bottom: true) {
    @include typo--lato--light;

    font-size: 28px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
}

@mixin typo--intro {
    @include typo--lato--light;

    font-size: 28px;
    line-height: 1.43;

    @media all and (max-width: $phablet) {
        font-size: 22px;
    }
}


@mixin typo--copy {
    @include typo--lato--light;

    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.5px;

    @media all and (max-width: $phablet) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
    }
}

// Classes

.typo--h1 {
    @include typo--h1;
}

.typo--h2 {
    @include typo--h2;
}

.typo--h3 {
    @include typo--h3;
}


@mixin link-style {
    color: $base--color--text;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

p {
    margin-bottom: spacer(2);

    &:last-child {
        margin-bottom: 0;
    }

    a:not(.m-button) {
        @include link-style;
    }
}

b,
strong {
    @include typo--lato--bold;
}

i,
em {
    @include typo--lato--light;
}

a {
    text-decoration: none;
    color: inherit;
}

a[href^="tel:"] {
    text-decoration: none;
    border: none !important;
}
