.m-hero {
    @include container(large);
    @include container-mb;

    position: relative;

    margin-top: spacer(-2);

    @media all and (max-width: $tablet--portrait) {
        padding: 0;
    }
}

.m-hero__image {
    @include ratio-image(16/9);

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include max-width(10);

    margin-left: auto;
}

.m-hero__box {
    position: relative;

    max-width: rem($base--page-max-width / 2);

    background-color: rgba($base--color--main--3, 0.95);

    padding: spacer(2);

    margin-top: rem(-200);

    margin-left: spacer(2);

    @media all and (max-width: $phablet) {
        margin-top: 0;

        max-width: 90%;
        margin: 0 auto;

        margin-top: rem(-50);

        padding: spacer(1.5);
    }
}

.m-hero__headline {
    @include typo--lato--regular;

    font-size: rem(60);
    line-height: 64px;

    @media all and (max-width: $tablet--portrait) {
        font-size: rem(36);
        line-height: rem(40);
    }
}

.m-hero__text {
    font-size: rem(28);
    line-height: 40px;

    margin-top: spacer(1);

    @media all and (max-width: $tablet--portrait) {
        font-size: rem(22);
        line-height: rem(28);
    }
}

.m-hero__button {
    @extend .m-button;
    @extend .m-button--primary;
    @extend .m-button--has-black-arrow;

    background-color: #fff;
    color: $base--color--text;

    margin-top: spacer(2);

    border: none;
}
