@mixin mod_text {
    @include typo--copy;

    h2 {
        @include typo--h2;

        margin-bottom: spacer(2);
    }

    h3 {
        @include typo--h3;

        margin-bottom: spacer(2);
    }

    a:not(.m-button) {
        @include link-style;
    }

    h2, h3 {
        text-align: left;

        &:not(:first-child) {
            padding-top: spacer(2);
        }
    }

    ul {
        margin-bottom: spacer(2);

        &:last-child {
            margin-bottom: 0;
        }

        li {
            position: relative;
            padding-left: spacer(1);
            list-style: none;
            display: list-item;

            &:before {
                content: '•';
                position: absolute;
                top: em(0);
                left: 0;
                color: $base--color--main--1;
            }
        }
    }

    ol {
        padding-left: rem(30);

        margin-bottom: spacer(3);

        li {
            position: relative;
            list-style: decimal;
            display: list-item;
        }
    }

    .is-centered {
        text-align: center;
    }
}

.m-text {
   @include mod_text();
}

.m-text--intro {
    @extend .m-text;

    @include typo--intro;
}
