.m-expert-teaser-small {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.32);

    display: flex;

    background-color: #fff;

    cursor: pointer;

    &:hover {
        @include more-link-hover;
    }

    @media all and (max-width: $phablet) {
        flex-wrap: wrap;
    }
}

.m-expert-teaser-small__image {
    @include ratio-image(1/1);

    flex: 0 0 rem(230);

    align-self: flex-start;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media all and (max-width: $phablet) {
        flex: 0 0 100%;
    }
}

.m-expert-teaser-small__content {
    padding: spacer(1);
}

.m-expert-teaser-small__name {
    @include typo--h3;

    text-align: left;

    margin-bottom: spacer(0.5);
}

.m-expert-teaser-small__profession {
    margin-bottom: spacer(0.5);
}

.m-expert-teaser-small__more {
    color: $base--color--main--1;
}
