.m-highlights-box {
    padding: spacer(2);

    background-color: $base--color--main--3;

    li {
        &:before {
            color: $base--color--text !important;
        }
    }

    @media all and (max-width: $phablet) {
        padding: spacer(1);
    }
}

.m-highlights-box__headline {
    @include typo--h2;

    margin-bottom: spacer(2);

    @media all and (max-width: $phablet) {
        margin-bottom: spacer(1)
    }
}
