.m-header {
    @include container(large);

    overflow: visible;

    position: relative;

    z-index: 1;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding-top: spacer(2);
    padding-bottom: spacer(2);

    @media all and (max-width: $phablet) {
        align-items: flex-end;
    }

    margin-bottom: spacer(2);
}

.m-header__logo {
    display: block;
    width: rem(180);

    background-image: url('../assets/images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &:after {
        display: block;
        content: '';

        padding-top: calc(100% / (151/92));
    }
}

.m-header__trigger {
    @include typo--lato--bold;

    display: none;

    cursor: pointer;

    text-transform: uppercase;

    line-height: 1;

    position: relative;

    width: rem(30);
    height: rem(23);

    background-image: url('../assets/images/icon--hamburger.svg');
    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
    text-indent: -99999px;

    @media all and (max-width: $phablet) {
        display: block;
        margin-left: auto;
    }
}

.m-header__nav {
    @include typo--lato--bold;

    font-size: rem(18);

    display: flex;

    text-transform: uppercase;

    li {
        a {
            padding: 0 spacer(1);

            &:hover,
            &.is-active {
                color: $base--color--main--1;
            }
        }
    }

    @media all and (max-width: $phablet) {
        position: absolute;

        display: block;

        transition: all 0.2s ease-in-out;
        opacity: 0;
        transform: translateY(-10px);
        pointer-events: none;

        background-color: #fff;

        padding: spacer(1);

        right: 0;

        top: 100%;
        right: spacer(1);

        margin-top: spacer(-1);

        background-color: $base--color--main--2;

        li {
            display: block;
            width: 100%;

            margin-bottom: spacer(1);

            color: #fff;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                padding: 0 spacer(1);

                &:hover,
                &.is-active {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }

        &.is-in {
            transition: all 0.2s ease-in-out;
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }
    }
}
