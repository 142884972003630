// Globals
@import 'global/reset';
@import 'global/fonts';
@import 'global/variables';
@import 'global/functions';
@import 'global/mixins';
@import 'global/typography';
@import 'global/z-index';
@import 'global/styleguide';

// Vendor
@import './../node_modules/flickity/dist/flickity';
@import './../assets/vendor-scripts/royalslider/royalslider';
@import './../node_modules/snazzy-info-window/dist/snazzy-info-window.scss';


@import './../assets/vendor-scripts/photoswipe/src/css/main';

$pswp__assets-path: '../assets/vendor-scripts/photoswipe/dist/default-skin/';
@import './../assets/vendor-scripts/photoswipe/src/css/default-skin/default-skin';


// Modules with mixins to be included within Modules
// @import 'modules/mod_more-link';
// @import 'modules/mod_back-link';

@import 'modules/mod_grid';
@import 'modules/mod_ratio-image';
@import 'modules/mod_container';
@import 'modules/mod_text';
// @import 'modules/mod_tags';
@import 'modules/mod_button';
@import 'modules/mod_more-link';

@import 'modules/mod_header';
@import 'modules/mod_hero';
@import 'modules/mod_intro';
@import 'modules/mod_teaser';
@import 'modules/mod_filter';
@import 'modules/mod_week-switcher';
@import 'modules/mod_event-teaser';
@import 'modules/mod_section-headline';
@import 'modules/mod_footer';
@import 'modules/mod_cookie-warning';
@import 'modules/mod_fact-box';
@import 'modules/mod_slideshow';
@import 'modules/mod_info-box';
@import 'modules/mod_cat-teaser';
@import 'modules/mod_bg-container';
@import 'modules/mod_date-teaser';
@import 'modules/mod_cat-teaser';
@import 'modules/mod_menue';
@import 'modules/mod_highlights-box';
@import 'modules/mod_image-text';
@import 'modules/mod_expert-teaser';
@import 'modules/mod_expert-teaser-small';
@import 'modules/mod_related-events';

// Modules
/*
@import 'modules/mod_nav';
@import 'modules/mod_space-bottom';
@import 'modules/mod_spacer';
@import 'modules/mod_main';
@import 'modules/mod_intro-image';
@import 'modules/mod_columns';
@import 'modules/mod_image';
@import 'modules/mod_select';
@import 'modules/mod_accordion';
@import 'modules/mod_slideshow';
@import 'modules/mod_features';
@import 'modules/mod_teaser-wide';
@import 'modules/mod_bg-container';
@import 'modules/mod_teaser-cluster';
@import 'modules/mod_tabs';
@import 'modules/mod_newsletter-signup';
@import 'modules/mod_icon';
@import 'modules/mod_vip';
@import 'modules/mod_opening-hours';
@import 'modules/mod_menue';
@import 'modules/mod_book-cta';
@import 'modules/mod_team';
@import 'modules/mod_big-menue';
@import 'modules/mod_foot-note';
@import 'modules/mod_text-image';
@import 'modules/mod_map';
@import 'modules/mod_cta-teaser';
@import 'modules/mod_teaser-big';
@import 'modules/mod_teaser-room';
@import 'modules/mod_teaser-small';
@import 'modules/mod_arrow-link';
@import 'modules/mod_list';
@import 'modules/mod_downloads';
@import 'modules/mod_slider';
@import 'modules/mod_testimonials';
@import 'modules/mod_cite';
@import 'modules/mod_booking-load-layer';
@import 'modules/mod_teaser-wall';
@import 'modules/mod_not-found';
@import 'modules/mod_count-down';
*/

.preload * {
	transition: none !important;
}

* {
	-webkit-tap-highlight-color: transparent;
}

/**
 * We need this class for iOS. Since iOS load with the browser bar on bottom
 * we need the actual height of the visible vieport to calculate the height of
 * Intro slide.
 */

.js-initial-viewvport-height {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    pointer-events: none;
    transform: translate3d(0, 0, 0);
}

.tito-wrapper {
    margin: 0 !important;

    padding: spacer(2);

    background-color: #F0F0F0;

    margin-bottom: spacer(4) !important;

    max-width: 100% !important;

    @media all and (max-width: $tablet--landscape) {
        padding: spacer(1);
    }
}

.tito-ticket {
    @include typo--lato--bold;

    display: flex;
    justify-content: space-between;

    padding: spacer(1) 0;

    border-bottom: 1px solid #DCDCDC;

    @media all and (max-width: $tablet--landscape) {
        display: block;
    }
}

.tito-ticket-name-wrapper {
    @media all and (max-width: $tablet--landscape) {
        margin-bottom: spacer(2);
    }
}

.tito-ticket-price-quantity {
    display: flex;

    @media all and (max-width: $tablet--landscape) {
        margin-left: auto;
    }
}

.tito-ticket-price-quantity-wrapper {
    @media all and (max-width: $tablet--landscape) {
        display: flex;
    }
}

.tito-ticket-quantity-field {
    font-size: rem(20);
    padding: spacer(1);

    width: rem(100);
    text-align: center;

    @media all and (max-width: $tablet--landscape) {
        font-size: rem(16);
        padding: spacer(0.5);
    }

    @media all and (max-width: $mobile) {
        width: rem(50);
    }
}

.tito-ticket-quantity span {
    margin: 0 spacer(1);
}

.tito-ticket-price {
    font-size: rem(24);
}

.tito-ticket-vat {
    font-size: rem(16);
    color: #999;
}

.tito-submit-wrapper {
    margin-top: spacer(2);
    text-align: right;

    margin-bottom: spacer(1);
}

.tito-submit {
    @extend .m-button;
    @extend .m-button--primary;
}

.tito-badge-link {
    text-align: center;

    font-size: rem(14);
}

.m-filter-note {
    @extend .m-button;
    @extend .m-button--primary;

    position: fixed;

    right: spacer(2);
    bottom: spacer(2);

    cursor: pointer;

    transition: transform $base--global-transition-speed ease-in-out;
    transform: translateY(200px);

    .html--filter-is-active & {
        transition: transform $base--global-transition-speed ease-in-out;
        transform: translateY(0);
    }

    @media all and (max-width: $tablet--landscape) {
        right: auto;
        left: 50%;
        transform: translateX(-50%) translateY(200px);

        .html--filter-is-active & {
            transition: transform $base--global-transition-speed ease-in-out;
            transform: translateX(-50%) translateY(0);
        }
    }
}

.flickity-enabled {
    display: block;
}

.flickity-button {
    transform: none;
    border-radius: 0;

    background-image: url('../assets/images/icon--arrow-left.svg');
    background-size: rem(16) rem(16);
    background-repeat: no-repeat;
    background-position: center center;

    width: rem(48);
    width: rem(48);

    background-color: $base--color--main--1;

    svg {
        display: none;
    }

    &.previous {
        background-image: url('../assets/images/icon--double-arrow-left.svg');
        left: 0;
    }

    &.next {
        background-image: url('../assets/images/icon--double-arrow-right.svg');
        right: 0;
    }

    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: rem(24) rem(24) !important;

    &:hover {
        background-color: $base--color--main--2;
    }

    &:active {
        opacity: 1 !important;
    }
}

.flickity-viewport {
    transition: height 0.2s;
}

.flickity-page-dots {
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: -1;

    .dot.is-selected {
        width: rem(16);
        height: rem(16);
    }
}
