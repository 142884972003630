@mixin _more-link-hover-efffect {
    &:after {
        display: block;
        content: '';

        transition: transform 0.3s ease-in-out;
        transform: translateY(-50%) translateX(5px);
    }
}

.m-more-link {
    @include typo--lato--bold;

    position: relative;

    color: $base--color--main--1;

    font-size: rem(16);

    text-transform: uppercase;

    display: inline-block;

    padding-right: spacer(1);

    cursor: pointer;

    &:hover {
        @include _more-link-hover-efffect();
    }

    &:after {
        content: "";
        display: inline-block;

        position: absolute;

        right: 0;
        top: 50%;

        transition: transform 0.3s ease-in-out;
        transform: translateY(-50%);

        transition: transform 0.2s ease-in-out;

        background-image: url('../assets/images/icon--double-arrow-right--pink.svg');
        background-size: contain;
        background-repeat: no-repeat;

        width: rem(12);
        height: rem(12);
    }
}

@mixin more-link-hover {
    .m-more-link {
        @include _more-link-hover-efffect();
    }
}
