/* Pass pixel to get them as em returned */
@function em($px) {
    @return $px / $base--font-size--unitless + em;
}

/* Pass pixel to get them as rem returned */
@function rem($px) {
    @return $px / $base--font-size--unitless + rem;
}
/* Use Base Font size as multiplier */
@function spacer($size) {
    @return ($size * $base--font-size--unitless / $base--font-size--unitless) + rem;
}
