@font-face {
    font-family: 'Chiswick Deck Web';
    src: url('../assets/webfonts/ChiswickDeck-Regular-Web.woff2') format('woff2'),
    url('../assets/webfonts/ChiswickDeck-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Chiswick Deck Web';
    src: url('../assets/webfonts/ChiswickDeck-LightItalic-Web.woff2') format('woff2'),
    url('../assets/webfonts/ChiswickDeck-LightItalic-Web.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Chiswick Deck Web';
    src: url('../assets/webfonts/ChiswickDeck-Light-Web.woff2') format('woff2'),
    url('../assets/webfonts/ChiswickDeck-Light-Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}