$slider__ratio: (100% / (16 / 9));
$slider__padding: 0; // Leave some room for prev/next
$slider__padding--tablet-p: 0;
$slider__padding--mobile: 0; // Currently we hide the prev/next arrows on mobile
$slider__slide-margin-bottom: space(1);
$slider__distanceToText: space(2);

.mod_slider__container {
    @include max-width(10);

    margin: 0 auto;
}

.mod_slider_padding-wrap {
    position: relative;
 }

.mod_slider__wrap {
    position: relative;

    padding-top: $slider__ratio;
}

.mod_slider__wrap--has-caption {
    // This is the space you define for the caption
    margin-bottom: em(100);

    @media all and (max-width: $phablet) {
        margin-bottom: em(140);
    }
}

.mod_slider {
    position: absolute;

    display: none;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    // Overwrite royalslider default fadein speed
    .rsContent {
        transition-duration: $base--global-transition-speed;
    }
}

.mod_slider__slide {
    position: relative;

    // Add background color to slides
    // background-color: $base--img-bg-color;

    .mod_slider__image__wrapper {
        position: relative;

        padding-top: $slider__ratio;
    }

    .mod_slider__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;

        picture,
        img {
            width: inherit;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
            // opacity: 0;

            // transition: opacity 0.4s ease-in-out;

            // &.lazyloaded {
            //     opacity: 1;
            // }
        }
    }
}

/**
 * Caption
 */

.mod_slider__caption {

}

.mod_slider__counter {
    @include typo--copy;

    position: absolute;
    top: 100%;
    right: 0;

    color: $base--color--main--1;

    margin-top: space(6);

    display: none;
}

.mod_slider__copyright {
    @include typo--copy;

    position: absolute;

    top: 0;
    left: 100%;
    top: 100%;

    transform: rotate(-90deg);
    transform-origin: 0 0;

    width: 100%;

    text-align: left;

    padding-left: em(100);

    @media all and (max-width: $phablet) {
        padding-left: em(50);
    }
}

.rsGCaption {
    left: 100%;

    bottom: 0;
}

.mod_slider__caption-text {
    @include typo--copy;
    position: absolute;

    margin-top: space(2);
    text-align: left;

    padding-right: rem(100);

    width: 100%;
}

/**
 * Arrow styles
 */

.mod_slider__prev,
.mod_slider__next {
    @include disable-selection;

    position: absolute;

    top: 100%;
    cursor: pointer;

    margin-top: rem(16);

    width: space(3);
    height: space(3);

    z-index: 100;

    &.is-disabled {
        opacity: 0.3;
    }

    @media all and (max-width: $phablet) {
        display: none;
    }
}

.mod_slider__prev {
    right: rem(50);

    // arrow
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;

        width: rem(32);
        height: rem(21);

        background-image: url('../assets/images/icon--arrow-left.svg');
        background-size: container;
        background-repeat: no-repeat;
        background-position: center center;

        transform: translateY(-50%);
    }

}

.mod_slider__next {
    right: 0;

    // arrow
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;

        width: rem(32);
        height: rem(21);

        background-image: url('../assets/images/icon--arrow-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        transform: translateY(-50%);
    }
}
